import React from "react";
import { Button } from "ui";

const OnboardingPage = () => {
  return (
    <>
      <Button />
    </>
  );
};

export default OnboardingPage;
